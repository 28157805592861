import React from 'react';
import { graphql } from 'gatsby';
import theme from '../utils/theme';
import { Card, ChakraProvider, Box, Flex, Heading, Grid, Link, Button, Text } from '@chakra-ui/react';
import SearchComponent from '../components/Search/SearchComponent';
import { useTranslation } from 'react-i18next';
import Container from '../components/Container';
import Banner from '../Sections/Banner';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Seo from '../components/SEO';
import { categories } from '../utils/data';
import { useState } from 'react';
import '../styles/index.css'

const LearnWithUs= () => {
  const { t } = useTranslation('learn-with-us');

  return (
    <ChakraProvider theme={theme}>
      <Flex direction="column" w="100%">
        <Header />
        <Box id="learn-with-us" px={{ base: '20px', md: '80px' }} py={10} textAlign="center">
          <Container>
            <Box mb={14}>
              <Heading as="h1" size="md" fontWeight="500" mb={6}>
                {t('learn-with-us.title')}
              </Heading>
              <SearchComponent/>
            </Box>
            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
              {categories.map((category) => (
                <CategoryCard key={category.title} category={category} />
              ))}
            </Grid>
          </Container>
        </Box>
        <Banner />
      </Flex>
      <Footer />
    </ChakraProvider>
  );
};

const CategoryCard = ({ category }) => {
  const { t } = useTranslation('learn-with-us');

  const [showAll, setShowAll] = useState(false);
  const questionsToShow = showAll ? category.questions : category.questions.slice(0, 4);

  return (
    <Card
      p="35px"
      textAlign="left"
      boxShadow="rgba(31, 22, 70, 0.25) 4px 4px 15px 1px"
      _hover={{ boxShadow: 'rgba(31, 22, 70, 0.45) 4px 4px 15px 1px' }}
    >
      <Heading as="h2" size="md" mb={1} fontSize="20px" fontWeight="500" color="#101828">
        {category.title}
      </Heading>
      <Box>
        {questionsToShow.map((question) => (
          <Link
            href={`/faqs/${question.slug}`}
            key={question.slug}
            display="block"
            mb="5px"
            fontSize="16px"
            color="#101828"
          >
            <h3>{question.title}</h3>
          </Link>
        ))}
      </Box>
      {category.questions.length > 4 && (
        <Button
          onClick={() => setShowAll(!showAll)}
          mt={4}
          color="#925632"
          fontWeight="500"
          fontSize="16px"
          textAlign="left"
          variant="link"
          display="block"
        >
          {showAll ? t('learn-with-us.show-less') : t('learn-with-us.show-more') }
        </Button>
      )}
    </Card>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default LearnWithUs; 

export const Head = () => {
  return <Seo title="Preguntas frecuentes" />;
};

